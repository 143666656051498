<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <title>Title</title>
</head>
<body>
  <div class="container">
    <div class="pdf-container">
      <pdf-viewer
        [original-size]="false"
        [src]="pdfSrc"
        style="height: 100%"
        (after-load-complete)="onPdfComplete($event)"
        (error)="onPdfError($event)"
        (on-progress)="onPdfProgress($event)"
      ></pdf-viewer>
    </div>
    <button *ngIf="!hideDownload" (click)="downloadPdf()">下载</button>
  </div>

  <!--loading-->
  <div class="loading-container" *ngIf="showLoading">
    <img class="loading" src="../assets/images/loading.gif" alt="">
  </div>

  <!--tips-->
  <div class="tips-container" *ngIf="showTips">
    <p class="tips">{{tipsMessage}}</p>
  </div>
</body>
</html>
