import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  pdfSrc = '';
  hideDownload = false;
  showLoading = false;
  showTips = false;
  tipsMessage = '';

  getUrl = '/api/v1/record/reportlink/';

  constructor(
    public http: HttpClient
  ) { }

  ngOnInit(): void {
    (window as any).pdfWorkerSrc = 'assets/js/pdf.worker.min.js';

    let list = location.href.split('?');
    let search = '';
    if (list.length > 1) {
      search = list[1];
    }
    let arr = search.split('&');
    let obj: any = {};
    arr.forEach(item => {
      let key = item.split('=')[0];
      let value = item.split('=')[1];
      if (key) {
        obj[key] = value;
      }
    });

    this.hideDownload = obj.hideDownload === 'true' ? true : false;
    if (obj.code_param) {
      this.http.get(`${this.getUrl}?code=${obj.code_param}`).subscribe((res: any) => {
        if (res.status) {
          this.pdfSrc = decodeURIComponent(res.data.download_url);
          this.showLoading = true;
        } else {
          this.showTipsHandle('报告获取失败');
          // 隐藏下载按钮
          this.hideDownload = true;
        }
      }
    );
    } else if (obj.url) {
      this.pdfSrc = decodeURIComponent(obj.url);
      this.showLoading = true;
    } else {
      this.showTipsHandle('报告获取失败');
      // 隐藏下载按钮
      this.hideDownload = true;
    }
  }

  onPdfProgress(progress: any) {
    console.log(progress);
    // this.showLoading = true;
  }
  onPdfComplete(res: any) {
    console.log(res);
    this.showLoading = false;
  }
  onPdfError(err: any) {
    console.log(err);
    this.showLoading = false;
    this.showTipsHandle('报告获取失败');

    // 隐藏下载按钮
    this.hideDownload = true;
  }
  showTipsHandle(message: string) {
    this.showTips = true;
    this.tipsMessage = message;

    setTimeout(() => {
      this.showTips = false;
    }, 2000)
  }
  downloadPdf() {
    window.open(this.pdfSrc);
  }
}
